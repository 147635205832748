import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box, Typography } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Motion } from "./Motion";

export default function InfoDialog({
  text,
  open = false,
  handleClose = () => {},
  closeButtonOnly = false,
  chooseFunction = null,
  submitText = "Ok",
  tooltip = null,
  error = null,
  dialog = undefined,
  closable = false,
}) {
  if (dialog) {
    text = dialog?.text || text;
    open = Boolean(dialog) || open;
    chooseFunction = dialog?.chooseFunction || chooseFunction;
    tooltip = dialog?.tooltip || tooltip;
    error = dialog?.error || error;
    submitText = dialog?.submitText || submitText;
    closable = dialog?.closable || closable;
    closeButtonOnly = dialog?.closeButtonOnly || closeButtonOnly;
  }

  if (!text) return <></>;
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        if (closeButtonOnly) return;
        handleClose(e);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        zIndex: 999999,
        ".MuiPaper-root": {
          borderRadius: "24px",
          minWidth: "310px",
          width: "410px",
          maxWidth: "430px",
          minHeight: "160px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          overflowX: "hidden",
        },
      }}
    >
      <DialogContent
        sx={[
          {
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            flexDirection: "column",
            p: "32px",
          }, // mt: "15px"
          chooseFunction && {
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          },
        ]}
      >
        <DialogContentText
          sx={{
            fontSize: "18px",
            color: "black",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
          }}
          id="alert-dialog-description"
        >
          {tooltip && (
            <InfoRounded sx={{ fill: "blue", mb: "20px", mt: "5px" }} />
          )}
          {error && !tooltip && (
            <CancelRoundedIcon sx={{ fill: "red", mb: "20px", mt: "5px" }} />
          )}
          {text}
        </DialogContentText>
        {(chooseFunction || closable || closeButtonOnly) && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Motion>
              <Box
                onClick={(e) => {
                  if (chooseFunction) {
                    chooseFunction();
                  }
                  handleClose();
                }}
                sx={{
                  mt: "32px",
                  bgcolor: "white",
                  cursor: "pointer",
                  borderRadius: "24px",
                  border: `2px solid black`,
                }}
              >
                <Typography sx={{ py: "8px", px: "26px", fontWeight: "bold" }}>
                  {submitText}
                </Typography>
              </Box>
            </Motion>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
