import {
  TwitterAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";

export const yahooProvider = new OAuthProvider("yahoo.com");
export const googleProvider = new GoogleAuthProvider();
export const twitterProvider = new TwitterAuthProvider();

yahooProvider.setCustomParameters({
  prompt: "login",
});
