import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  appGreen,
  appGrey,
  appRed,
  appYellow,
  boxPadding,
  textColor,
} from "../Styles/styles";
import { Motion } from "./Motion";

import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";
import PrivacyTipRoundedIcon from "@mui/icons-material/PrivacyTipRounded";

export default function WarningLayout({ title = "", text = "" }) {
  const [hidden, setHidden] = useState(false);
  const [removed, setRemoved] = useState(false);

  useEffect(() => {
    if (hidden) {
      setTimeout(() => {
        setRemoved(true);
      }, 300);
    }
  }, [hidden]);

  if (removed) {
    return <></>;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        opacity: hidden ? 0 : 1,
        transition: "max-height 0.3s ease",
        maxHeight: hidden ? 0 : "1200px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          borderRadius: "12px",
          mb: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ...boxPadding,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Motion>
              <Box
                sx={{
                  height: "25px",
                  maxHeight: "25px",
                }}
              >
                {/*<WarningRoundedIcon sx={{ fill: appRed }} />*/}
                {/* <TipsAndUpdatesRoundedIcon sx={{ fill: appYellow }} />*/}
                <PrivacyTipRoundedIcon sx={{ fill: appGreen }} />
              </Box>
            </Motion>
            <Typography
              component="h2"
              sx={{ ml: "8px", fontWeight: "bold", fontSize: "18px" }}
            >
              {title}
            </Typography>
            <Motion>
              <Box
                sx={{
                  ml: "auto",
                  cursor: "pointer",
                  p: "3px",
                  height: "24px",
                  maxHeight: "24px",
                }}
                onClick={() => {
                  setHidden(true);
                }}
              >
                <CloseRoundedIcon sx={{ fill: textColor }} />
              </Box>
            </Motion>
          </Box>
          <Typography sx={{ textAlign: "left", mt: "8px" }}>{text}</Typography>
        </Box>
      </Box>
    </Container>
  );
}
