import { Alert, Box, Snackbar, Typography } from "@mui/material";
import React from "react";
import Google from "./Google/Google";
import Twitter from "./Twitter/Twitter";
import { borderColor } from "../../Styles/styles";
import { Motion } from "../Motion";
import {
  googleProvider,
  twitterProvider,
  yahooProvider,
} from "../../Firebase/auth";
import { signInWithPopup } from "firebase/auth";
import { auth } from "../../Firebase/init";
import Yahoo from "./Yahoo/Yahoo";

export const siIconSize = "22px";

const methods = [
  {
    icon: <Google />,
    name: "Google",
    bgcolor: "white",
    txtcolor: "black",
    provider: googleProvider,
  },
  {
    icon: <Twitter />,
    name: "",
    bgcolor: "black",
    txtcolor: "white",
    reverse: true,
    provider: twitterProvider,
  },
  {
    icon: <Yahoo />,
    name: "Yahoo",
    bgcolor: "#410093",
    txtcolor: "white",
    provider: yahooProvider,
  },
];

export default function SignInMethods({ texts, onSignIn }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: "32px" }}>
      {methods.map((data, i) => (
        <Motion key={"SignInButton" + i} scale={1.03}>
          <Box
            sx={{
              border: `1px solid ${borderColor}`,
              borderRadius: "6px",
              bgcolor: data.bgcolor,
              mb: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              signInWithPopup(auth, data.provider)
                .then((result) => {
                  console.log(result);
                  onSignIn();
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: data.reverse ? "row-reverse" : "row",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                p: "8px",
                mx: "12px",
              }}
            >
              {data.icon}
              <Typography sx={{ color: data.txtcolor, mx: "6px" }}>
                {texts.siw} {data.name}
              </Typography>
            </Box>
          </Box>
        </Motion>
      ))}
    </Box>
  );
}
