import {
  Autocomplete,
  Box,
  Container,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useNavigate, useSearchParams } from "react-router-dom";
import Books from "./Navbar/Books";
import CookieIcon from "@mui/icons-material/Cookie";
import { theme } from "../../App";
import CookieBar from "./CookieBar";
import { textColor } from "../Styles/styles";
import { Motion } from "./Motion";
import PhoneInput from "react-phone-input-2";

function Logo({ color = textColor }) {
  const xss = useMediaQuery(theme.breakpoints.down("340"));
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang");

  return (
    <Box
      component="a"
      sx={{
        display: "flex",
        flexDirection: "row",

        alignItems: "center",
        alignContent: "center",
        textDecoration: "none",
      }}
      href={`https://callnumbers.org${lang ? `?lang=${lang}` : ``}`}
    >
      <Books fill={color} style={{ width: "24px", height: "24px" }} />

      {!xss && (
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "900",
            mx: "7px",
            color,
            textDecoration: "none",
          }}
        >
          CALLNUMBERS
        </Typography>
      )}
    </Box>
  );
}

function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

function SearchBar({ color = "#000000", removeOutline = false, country }) {
  const [inputValue, setInputValue] = useState("");
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang");

  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          ml: "auto",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <form
          onSubmit={() => {
            navigate(`/+${inputValue}${lang ? `?lang=${lang}` : ""}`);
          }}
        >
          <PhoneInput
            country={String(country).toLowerCase()}
            value={inputValue}
            onChange={setInputValue}
            placeholder={""}
            onEnterKeyPress={(e) => {
              navigate(`/+${inputValue}${lang ? `?lang=${lang}` : ""}`);
            }}
            inputProps={{
              type: isAndroid() ? "tel" : "text",
              inputMode: isAndroid() ? "tel" : "text",
            }}
          />
        </form>
      </Box>
    </>
  );
}

export default function Footer({ threshold = 0, texts, ...props }) {
  const [cookieOpen, setCookieOpen] = useState(false);
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const { bg, txt, removeOutline, country } = props;

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: window ? window : undefined,
  });

  return (
    <footer>
      <CookieBar
        open={cookieOpen}
        setOpen={setCookieOpen}
        {...props}
        texts={texts}
      />
      <Box
        sx={{
          width: "100%",
          // position: "absolute",
          //bottom: 0,
          bgcolor: bg,
          boxShadow: elevated ? "0px -1px 5px 0px rgba(0,0,0,0.05)" : "none",
          minWidth: "310px",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            height: "160px",
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            alignContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              py: "32px",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              alignContent: "flex-start",
            }}
          >
            <Logo color={txt} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: "auto",
                alignItems: "flex-end",
                alignContent: "flex-end",
                height: "100%",
              }}
            >
              <SearchBar
                color={txt}
                removeOutline={removeOutline}
                country={country}
              />
              <Motion>
                <Box
                  sx={{
                    mt: "auto",
                    width: "124px",
                    height: "31px",
                    bgcolor: txt,
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    cursor: "pointer",
                    opacity: 1,
                  }}
                  onClick={() => {
                    setCookieOpen(true);
                  }}
                >
                  <CookieIcon
                    sx={{
                      fill: bg,
                      mr: "auto",
                      width: "30px",
                      height: "30px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: bg,
                      fontWeight: "bold",
                      pr: { xs: "30px", sm: "22px" },
                      fontSize: "14px",
                      pt: "2px",
                    }}
                  >
                    {"Cookies"}
                  </Typography>
                </Box>
              </Motion>
            </Box>
          </Box>
        </Container>
      </Box>
    </footer>
  );
}
