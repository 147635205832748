import { Box } from "@mui/material";
import React from "react";
import { borderColor } from "../Styles/styles";

function getMaxEntries(table) {
  if (!table || table.length === 0) return 0;
  let entries = 0;
  for (let i = 0; i < table.length; i++) {
    if (table?.[i]?.length > entries) {
      entries = table[i].length;
    }
  }
  return entries;
}

const cellStyle = {
  border: `1px solid ${borderColor}`,
  textAlign: "left",
  padding: "14px",
};

export default function Table({ dataTable = [[]] }) {
  const entries = getMaxEntries(dataTable);

  function generateLine(table) {
    let arr = [];
    for (let j = 0; j < entries; j++) {
      arr.push(
        <td
          key={"DataTableTh" + j}
          style={{
            ...cellStyle,
            lineHeight: "18px",
            fontSize: "16px",
          }}
        >
          <div>{table?.[j] ? table[j] : ""}</div>
        </td>
      );
    }
    return arr;
  }

  return (
    <Box sx={{ mt: "12px" }}>
      <style>{``}</style>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          borderRadius: "12px",
          borderStyle: "hidden",
          boxShadow: `0 0 0 1px ${borderColor}`,
        }}
      >
        <tbody>
          {dataTable.map((table, i) => (
            <tr key={"DataTableTr" + i}>{generateLine(table)}</tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
}
