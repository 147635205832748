import parsePhoneNumberFromString, {
  parsePhoneNumber,
} from "libphonenumber-js/max";
import React, { useEffect, useState } from "react";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
import { useCountryCode } from "../Countries/useCountryCode";
import { appBgColor, navPalette } from "../Styles/styles";
import MainPage from "./MainPage";
import { Box } from "@mui/material";
import Footer from "../Components/Footer";
import { getRandomArticles, getTexts } from "../Languages/scripts/languages";
import NotFoundPage from "./NotFoundPage";
import Loading from "../Components/Loading";
import Helmet from "../Components/Helmet";
import CookieBar from "../Components/CookieBar";
import CookiesPage from "./CookiesPage";
import AboutUs from "../Components/AboutUs";
import WarnPage from "./WarnPage";

export default function MainContainer({}) {
  const [texts, setTexts] = useState(null);
  const { number } = useParams();
  const countryCode = useCountryCode();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang");

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [colors, setColors] = useState(null);
  const { bg, txt } = colors || {};

  useEffect(() => {
    const loadLangs = async () => {
      const res = await getTexts(lang ? lang : countryCode);
      res.default.getRandomArticles = getRandomArticles;
      setTexts(res.default);
    };
    loadLangs();
  }, [countryCode]);

  useEffect(() => {
    let locCol =
      colors || navPalette[Math.floor(Math.random() * navPalette.length)];
    let { bg: bgLoc, txt: txtLoc } = locCol;
    while (bgLoc === bg && txtLoc === txt) {
      locCol = navPalette[Math.floor(Math.random() * navPalette.length)];
      bgLoc = locCol.bg;
      txtLoc = locCol.txt;
    }
    setColors(locCol);
  }, [location]);

  useEffect(() => {
    let finalNumber;
    if (number) {
      try {
        finalNumber = parsePhoneNumberFromString(number);
        if (!finalNumber) {
          throw new Error();
        }
      } catch (error) {
        try {
          finalNumber = parsePhoneNumber(number, countryCode);
          if (!finalNumber) {
            throw new Error();
          }
        } catch (countryError) {
          try {
            finalNumber = parsePhoneNumberFromString("+" + number);
          } catch (plusError) {
            console.log(plusError);
          }
        }
      }
    }
    if (!finalNumber) {
      // show invalid number page
    } else {
      console.log(finalNumber);
    }

    setPhoneNumber(finalNumber);
  }, [countryCode, number]);

  if (!texts) return <Loading open={true} />;

  function PageContainer() {
    const tpProps = {
      ...colors,
      phoneNumber,
      countryCode,
      texts,
    };

    if (location.pathname === "/cookies") {
      return <CookiesPage {...tpProps} />;
    } else if (location.pathname === "/about") {
      return <AboutUs texts={texts} />;
    } else if (location.pathname === "/warn") {
      return <WarnPage texts={texts} />;
    }

    if (!number) {
      return <NotFoundPage {...tpProps} main={true} />;
    } else if (!phoneNumber) {
      return <NotFoundPage {...tpProps} />;
    } else {
      return <MainPage {...tpProps} />;
    }
  }

  return (
    <Helmet phoneNumber={phoneNumber} texts={texts}>
      <div
        style={{
          backgroundColor: appBgColor,
          minHeight: "100vh",
          minWidth: "310px",
        }}
      >
        <Navbar {...colors} country={countryCode} texts={texts} />
        <Box sx={{ pt: "80px", pb: "30px" }}>
          <PageContainer />
        </Box>
        <CookieBar {...colors} texts={texts} />
      </div>
      <Footer {...colors} country={countryCode} texts={texts} />
    </Helmet>
  );
}
