import React from "react";
import { siIconSize } from "../SignInMethods";

export default function Yahoo() {
  return (
    <div style={{ width: siIconSize, height: siIconSize }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 3386.34 3010.5"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        style={{}}
      >
        <path
          d="M0 732.88h645.84l376.07 962.1 380.96-962.1h628.76l-946.8 2277.62H451.98l259.19-603.53L.02 732.88zm2763.84 768.75h-704.26L2684.65 0l701.69.03-622.5 1501.6zm-519.78 143.72c216.09 0 391.25 175.17 391.25 391.22 0 216.06-175.16 391.23-391.25 391.23-216.06 0-391.19-175.17-391.19-391.23 0-216.05 175.16-391.22 391.19-391.22z"
          fill="#fff"
          fillRule="nonzero"
        />
      </svg>
    </div>
  );
}
