import {
  Alert,
  Box,
  Container,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import GppBadRoundedIcon from "@mui/icons-material/GppBadRounded";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import { addRating } from "../Firebase/firestore";
import Loading from "./Loading";
import InfoDialog from "./InfoDialog";
import SignInDialog from "./SignIn/SignInDialog";
import { auth } from "../Firebase/init";

const cookie = "rate_";

function addNumberRatingCookie(number, code) {
  localStorage.setItem(`${cookie + code + number}`, "true");
}

function isNumberRatedCookie(number, code) {
  return Boolean(localStorage.getItem(`${cookie + code + number}`));
}

export const getRateOptions = (texts) => [
  {
    icon: <StarRateRoundedIcon sx={{ fill: "#f1c40f" }} />,
    text: texts.u,
    short: "u",
    color: "#f1c40f",
  },
  {
    icon: <CheckCircleRoundedIcon sx={{ fill: "#28b463" }} />,
    text: texts.s,
    short: "s",
    color: "#28b463",
  },
  {
    icon: <GppBadRoundedIcon sx={{ fill: "#959595" }} />,
    text: texts.n,
    short: "n",
    color: "#959595",
  },
  {
    icon: <ReportProblemRoundedIcon sx={{ fill: "#e74c3c" }} />,
    text: texts.a,
    short: "a",
    color: "#e74c3c",
  },
  {
    icon: <BugReportRoundedIcon sx={{ fill: "#f32813" }} />,
    text: texts.d,
    short: "d",
    color: "#f32813",
  },
];

function RateButton({ ...props }) {
  const { bg, txt, select, texts, setSelect, removeOutline, country } = props;
  const rateOptions = getRateOptions(texts);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        m: { xs: "0px", sm: "20px" },
        mt: { xs: "24px", sm: "20px" },
      }}
    >
      {rateOptions.map((data, i) => (
        <Box
          key={"RatingOpt" + i}
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: "-1px",
            p: "16px",
            px: { xs: "28px", sm: "35px" },
            borderTopLeftRadius: i === 0 ? "12px" : "0px",
            borderTopRightRadius: i === 0 ? "12px" : "0px",
            borderBottomLeftRadius:
              i === rateOptions.length - 1 ? "12px" : "0px",
            borderBottomRightRadius:
              i === rateOptions.length - 1 ? "12px" : "0px",
            cursor: "pointer",
            "&:hover": {
              bgcolor: bg,
              opacity: 0.8,
            },
            border: select === i ? `2px solid ${txt}` : `2px solid ${txt}`,
            zIndex: select === i ? "2" : "1",
            borderBottomWidth: i === rateOptions.length - 1 ? "2px" : "0px",
            //borderTopWidth: select && select + 1 === i ? "0px" : "2px",
          }}
          onClick={() => {
            if (select === i) {
              setSelect(null);
              return;
            }
            setSelect(i);
          }}
        >
          {data.icon}{" "}
          <Typography
            sx={{
              color: select === i ? txt : "white",
              fontWeight: "bold",
              ml: { xs: "28px", sm: "8px" },
            }}
          >
            {data.text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

export default function AddRating({ ...props }) {
  const [signInDialog, setSignInDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");
  const [alert, setAlert] = useState(false);
  const { bg, txt, phoneNumber, texts, onDataAdd } = props;

  function letterFromRate() {
    switch (rating) {
      case 0:
        return "u";
      case 1:
        return "s";
      case 2:
      default:
        return "n";
      case 3:
        return "a";
      case 4:
        return "d";
    }
  }

  function onSignIn() {
    setSignInDialog(false);
    submitRating();
  }

  async function submitRating() {
    if (
      isNumberRatedCookie(
        phoneNumber?.nationalNumber,
        phoneNumber?.countryCallingCode
      )
    ) {
      setDialog({ text: texts.artn, closable: true });
      return;
    } else if (rating === null) {
      setDialog({ text: texts.cyrf, closable: true });
      return;
    }

    // Login to comment
    if (comment && !auth?.currentUser) {
      setSignInDialog(true);
      return;
    }

    setLoading(true);
    addRating(
      phoneNumber?.nationalNumber,
      phoneNumber?.countryCallingCode,
      letterFromRate(),
      comment
    )
      .then((rateData) => {
        setComment("");
        setRating(null);
        addNumberRatingCookie(
          phoneNumber?.nationalNumber,
          phoneNumber?.countryCallingCode
        );
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 3000);
        onDataAdd(rateData);
        setLoading(false);
      })
      .catch((err) => {
        if (err.code === "permission-denied") {
          setDialog({ text: texts.artn, closable: true });
          return;
        }
        console.log(err);
        setDialog({ text: texts.sww, closable: true });
        setLoading(false);
      });
  }

  return (
    <Container maxWidth="lg" sx={{ pb: "24px" }}>
      <Snackbar open={alert} autoHideDuration={4000}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {texts.su}
        </Alert>
      </Snackbar>
      <SignInDialog
        open={signInDialog}
        texts={texts}
        handleClose={() => {
          setSignInDialog(false);
        }}
        onSignIn={onSignIn}
      />
      <InfoDialog
        dialog={dialog}
        handleClose={() => {
          setDialog(null);
        }}
        submitText={texts.co}
      />
      <Loading open={loading} />
      <Box
        sx={{
          borderRadius: "12px",
          bgcolor: bg,
          minHeight: "400px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            px: { xs: "20px", sm: "20px", md: "100px" },
            pt: { xs: "28px", sm: "60px" },
            justifyContent: "center",
          }}
        >
          <TextField
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            label=""
            variant="outlined"
            multiline
            minRows={11}
            maxRows={11}
            inputProps={{
              maxLength: 100,
            }}
            sx={{
              maxWidth: { xs: "auto", sm: "300px", md: "480px" },

              mr: "auto",
              m: { xs: "0px", sm: "20px" },
              mt: { xs: "0px", sm: "24px" },
              width: "100%",
              "& .MuiInputBase-input::placeholder": {
                color: "#333",
                opacity: 1,
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                bgcolor: "white",
                mt: "-4px",
                // Outline
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "12px",
                  borderColor: bg,
                  borderWidth: "2px",
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: txt,
                    borderWidth: "2px",
                  },
                },
                // Text
                fontWeight: "normal",
                "& .MuiInputLabel-outlined": {
                  color: bg,
                  fontWeight: "normal",
                  "&.Mui-focused": {
                    color: txt,
                    fontWeight: "bold",
                  },
                },
              },
            }}
            placeholder={texts.ypcr}
          />
          <RateButton
            {...props}
            select={rating}
            setSelect={setRating}
            texts={texts}
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              border: `2px solid ${txt}`,
              borderRadius: "24px",
              width: "fit-content",
              cursor: "pointer",
              "&:hover": {
                bgcolor: bg,
                opacity: 0.8,
              },
              mt: { xs: "60px", sm: "32px" },
              mb: { xs: "64px", sm: "80px" },
            }}
            onClick={submitRating}
          >
            <Typography
              sx={{
                color: "white",
                px: "32px",
                py: "12px",
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: "15px",
              }}
            >
              {texts.sub}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
