import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { boxPadding, textBlue, textColor } from "../Styles/styles";
import { Motion } from "./Motion";
import Table from "./Table";
import { ratingsLetters } from "../Firebase/firestore";

export function dateToVisual(oldDate) {
  if (!oldDate) return "-";
  try {
    let date = new Date(oldDate);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  } catch (err) {
    return "-";
  }
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function timestampToDate(timestamp) {
  try {
    if (isValidDate(timestamp)) {
      return timestamp;
    }
    let date = new Date(timestamp.toDate());
    if (!isValidDate(date)) {
      throw new Error("Invalid Date");
    }
    return date;
  } catch (err) {
    let seconds = timestamp?.seconds || timestamp?._seconds;
    if (seconds) {
      let date = new Date(seconds * 1000);
      return date;
    } else {
      return null;
    }
  }
}

export default function InformationTable({ phoneNumber, texts, docData }) {
  const [hidden, setHidden] = useState(false);
  const [removed, setRemoved] = useState(false);

  function getRatingsData() {
    let data = {
      ratings: 0,
      comments: 0,
      score: undefined,
      lastComment: "-",
    };
    if (!docData || Object.keys(docData).length === 0) return data;
    // Ratings
    let initScore = 0;
    for (let i = 0; i < ratingsLetters.length; i++) {
      const rt = docData?.[ratingsLetters[i]];
      if (rt) {
        data.ratings += rt;
        if (i !== 0) {
          console.log(rt, i);
          initScore += rt * i;
        }
      }
    }
    if (data.ratings !== 0) {
      data.score = initScore / data.ratings;
    }
    // Comments
    if (docData?.comments?.length > 0) {
      data.comments += docData.comments.length;

      // Last one
      const mostRecent = docData.comments.sort(
        (a, b) => timestampToDate(b.date) - timestampToDate(a.date)
      )[0];
      data.lastComment = dateToVisual(timestampToDate(mostRecent.date));
    }

    return data;
  }

  function ScoreDisplay({ score = undefined }) {
    let color = "#959595";
    let text = texts.un;
    console.log("score", score);
    if (score !== undefined) {
      if (score !== 0) {
        text = texts.n;
      }
      if (score < 1.8) {
        text = texts.ba;
        color = "#f32813";
      } else if (score > 2.5) {
        text = texts.go;
        color = "#28b463";
      }
    }

    return <Typography sx={{ color, fontWeight: "bold" }}>{text}</Typography>;
  }

  const rateData = useMemo(() => getRatingsData(), [docData]);
  const titles = [
    [<b>{texts.nor}:</b>, <b>{rateData.ratings}</b>],
    /*[<b>{texts.lr}:</b>, <></>],*/
    [<b>{texts.noc}:</b>, <b>{rateData.comments}</b>],
    [<b>{texts.lc}:</b>, <Typography>{rateData.lastComment}</Typography>],
    [<b>{texts.or}:</b>, <ScoreDisplay score={rateData.score} />],
  ];

  useEffect(() => {
    if (hidden) {
      setTimeout(() => {
        setRemoved(true);
      }, 300);
    }
  }, [hidden]);

  if (removed) {
    return <></>;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        opacity: hidden ? 0 : 1,
        transition: "max-height 0.3s ease",
        maxHeight: hidden ? 0 : "1200px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          borderRadius: "12px",
          mb: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ...boxPadding,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Motion>
              <Box
                sx={{
                  height: "25px",
                  maxHeight: "25px",
                }}
              >
                <InfoRoundedIcon sx={{ fill: textBlue }} />
              </Box>
            </Motion>
            <Typography
              sx={{ ml: "8px", fontWeight: "bold", fontSize: "18px" }}
            >
              {`${texts.ai} (${phoneNumber?.number})`}
            </Typography>
            <Motion>
              <Box
                sx={{
                  ml: "auto",
                  cursor: "pointer",
                  p: "3px",
                  height: "24px",
                  maxHeight: "24px",
                }}
                onClick={() => {
                  setHidden(true);
                }}
              >
                <CloseRoundedIcon sx={{ fill: textColor }} />
              </Box>
            </Motion>
          </Box>
          <Table dataTable={titles} />
        </Box>
      </Box>
    </Container>
  );
}
