import { arrayUnion, doc, getDoc, increment, setDoc } from "firebase/firestore";
import { auth, db } from "./init";

export const ratingsLetters = ["d", "a", "n", "s", "u"]; //["u", "s", "n", "a", "d"];

function getUniqueByUid(arr) {
  const uniqueObjects = [];
  const seenUids = new Set();

  arr.forEach((obj) => {
    if (obj?.uid && !seenUids.has(obj.uid)) {
      uniqueObjects.push(obj);
      seenUids.add(obj.uid);
    }
  });

  return uniqueObjects;
}

export const getNumberDoc = async (
  number, // local number: 123456789
  countryCode // country code: 420
) => {
  try {
    const docRef = doc(db, countryCode, number);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let data = docSnap.data();
      if (data.comments) {
        data.comments = getUniqueByUid(data.comments);
      }
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const addRating = async (
  number, // local number: 123456789
  countryCode, // country code: 420
  rating, // code of rating: u (usefull), s (safe),... etc, always only one letter
  comment // string user comment
) => {
  try {
    if (
      !rating ||
      rating.length != 1 ||
      (rating !== "u" &&
        rating !== "s" &&
        rating !== "n" &&
        rating !== "a" &&
        rating !== "d")
    ) {
      throw new Error("Wrong rate data");
    }

    const docRef = doc(db, countryCode, number);

    let rateData;

    if (!comment || String(comment).trim().length === 0) {
      rateData = {
        letter: rating,
      };

      await setDoc(docRef, { [rating]: increment(1) }, { merge: true });
    } else {
      const date = new Date();

      const commentData = {
        text: comment,
        [rating]: true,
        date,
        uid: auth?.currentUser?.uid,
      };
      rateData = {
        letter: rating,
        text: comment,
        date,
      };

      await setDoc(
        docRef,
        {
          comments: arrayUnion(commentData),
          [rating]: increment(1),
        },
        { merge: true }
      );
    }

    return rateData;
  } catch (error) {
    throw error;
  }
};
