import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { boxPadding } from "../Styles/styles";

export default function AboutUs({ texts }) {
  return (
    <Container maxWidth="lg" sx={{}}>
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ...boxPadding,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
              {texts.au}
            </Typography>
          </Box>
          <Typography
            sx={{ textAlign: "left", mt: "8px", whiteSpace: "pre-line" }}
          >
            {texts.aut}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
