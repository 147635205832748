import React from "react";
import PhoneNumber from "../Components/PhoneNumber";
import WarningLayout from "../Components/WarningLayout";
import AboutUs from "../Components/AboutUs";

export default function NotFoundPage({ phoneNumber, texts, main, ...props }) {
  const articles = texts.getRandomArticles(texts.articles, 2);

  return (
    <div>
      <PhoneNumber
        phoneNumber={phoneNumber}
        texts={texts}
        isNotFound={true}
        main={main}
      />
      <WarningLayout title={articles?.[0]?.title} text={articles?.[0]?.text} />
      <WarningLayout title={articles?.[1]?.title} text={articles?.[1]?.text} />
      <AboutUs texts={texts} />
    </div>
  );
}
