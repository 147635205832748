import {
  Autocomplete,
  Box,
  Container,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Books from "./Books";
import { textColor } from "../../Styles/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { theme } from "../../../App";

function Logo({ color = textColor }) {
  const xss = useMediaQuery(theme.breakpoints.down("340"));
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang");

  return (
    <Box
      component="a"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        textDecoration: "none",
      }}
      href={`https://callnumbers.org${lang ? `?lang=${lang}` : ``}`}
    >
      <Books fill={color} style={{ width: "24px", height: "24px" }} />

      {!xss && (
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "900",
            mx: "7px",
            color,
            textDecoration: "none",
          }}
        >
          CALLNUMBERS
        </Typography>
      )}
    </Box>
  );
}

function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

function SearchBar({ color = "#000000", removeOutline = false, country }) {
  const [inputValue, setInputValue] = useState("");
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang");
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          ml: "auto",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <form
          onSubmit={() => {
            navigate(`/+${inputValue}${lang ? `?lang=${lang}` : ""}`);
          }}
        >
          <PhoneInput
            country={String(country).toLowerCase()}
            value={inputValue}
            onChange={setInputValue}
            placeholder={""}
            onEnterKeyPress={(e) => {
              navigate(`/+${inputValue}${lang ? `?lang=${lang}` : ""}`);
            }}
            inputProps={{
              type: isAndroid() ? "tel" : "text",
              inputMode: isAndroid() ? "tel" : "text",
            }}
          />
        </form>
      </Box>
    </>
  );
}

export default function Navbar({ threshold = 0, texts, ...props }) {
  const { word } = useParams();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang");
  const { bg, txt, removeOutline, country } = props;

  const navigation = [
    {
      name: texts.wa,
      link: `https://callnumbers.org/warn${lang ? `?lang=${lang}` : ``}`,
    },
    {
      name: texts.ab,
      link: `https://callnumbers.org/about${lang ? `?lang=${lang}` : ``}`,
    },
  ];

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: window ? window : undefined,
  });

  return (
    <header>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          top: 0,
          bgcolor: bg,
          boxShadow: elevated ? "0px 1px 5px 0px rgba(0,0,0,0.05)" : "none",
          zIndex: 1000,
          minWidth: "310px",
        }}
      >
        <Container maxWidth="lg" sx={{ height: "50px", width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Logo color={txt} />
            <Box
              sx={{ display: { xs: "none", sm: "flex" }, flexDirection: "row" }}
            >
              {navigation.map((nav, i) => (
                <Typography
                  component={"a"}
                  key={"NavText" + i}
                  sx={{
                    fontSize: "14px",
                    ml: "16px",
                    color: txt,
                    textDecoration: "none",
                    px: "4px",
                  }}
                  href={nav.link}
                >
                  {nav.name}
                </Typography>
              ))}
            </Box>
            <SearchBar
              color={txt}
              removeOutline={removeOutline}
              country={country}
            />
          </Box>
        </Container>
      </Box>
    </header>
  );
}
