const arabic = [
  "dz",
  "bh",
  "km",
  "dj",
  "eg",
  "iq",
  "jo",
  "kw",
  "lb",
  "ly",
  "mr",
  "ma",
  "om",
  "ps",
  "qa",
  "so",
  "sd",
  "sy",
  "tn",
  "ae",
  "ye",
];

const spanish = [
  "ar",
  "bo",
  "cl",
  "co",
  "cr",
  "cu",
  "do",
  "ec",
  "sv",
  "gq",
  "gt",
  "hn",
  "mx",
  "ni",
  "pa",
  "py",
  "pe",
  "es",
  "uy",
  "ve",
];

const china = ["sg"];

const remap = {
  sa: arabic,
  es: spanish,
  cn: china,
};

export const tryLangRemap = (lowerCode) => {
  const keys = Object.keys(remap);
  for (let i = 0; i < keys.length; i++) {
    if (remap[keys[i]].includes(lowerCode)) {
      return String(keys[i]);
    }
  }
  return lowerCode;
};
