import React from "react";
import { Helmet as RHelmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";

export default function Helmet({ children, phoneNumber, texts }) {
  const { word } = useParams();
  const location = useLocation();

  let title;
  let description;

  if (!phoneNumber) {
    title = `${texts.pnc} | Callnumbers.org`;
    description = `${texts.pnc} | ${texts.wcm} | ${texts.itps} | ${texts.vpnr} | Callnumbers.org`;
  } else {
    const number = phoneNumber?.nationalNumber || "";
    const code = phoneNumber?.countryCallingCode
      ? `+${phoneNumber?.countryCallingCode}`
      : "";
    title = `${code} ${number} | ${texts.pnc} | Callnumbers.org | ${number}`;
    description = `${texts.wcm} ${code} ${number} | ${texts.itps} ${number} | ${texts.vpnr} | Callnumbers.org`;
  }

  return (
    <>
      <RHelmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </RHelmet>
      {children}
    </>
  );
}
