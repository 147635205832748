import { tryLangRemap } from "./remaps";

export const languageCodes = [
  "en", // English
  "cz", // Czech
  "sa", // Arabic
  "es", // Spanish
  "cn", // Chinesse
];

// TODO: Japanese Indian Turkish Russian German

export const getTexts = async (countryCode) => {
  const lowerCode = tryLangRemap(String(countryCode).toLowerCase());

  for (let i = 0; i < languageCodes.length; i++) {
    if (lowerCode === languageCodes[i]) {
      const module = await import(`../${languageCodes[i]}`);
      return module;
    }
  }
  const module = await import(`../${languageCodes[0]}`);
  return module;
};

export const getRandomArticles = (arr, count) => {
  if (count > arr.length || !count) {
    return arr;
  }

  const shuffledArray = [...arr].sort(() => Math.random() - 0.5);
  return shuffledArray.slice(0, count);
};
