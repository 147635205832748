import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Box, LinearProgress } from "@mui/material";

export default function Loading({ open, handleClose }) {
  if (!open) return <></>;

  return (
    <>
      <Backdrop
        sx={{
          bgcolor: "rgba(0,0,0,0.15)",
          transition: "unset",
          zIndex: 99999999,
        }}
        open={open}
        onClick={handleClose}
      >
        <Box sx={{ width: "100%", position: "fixed", top: 0 }}>
          <LinearProgress
            sx={{
              //bgcolor: navbarBlue,
              ".MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#5555cc",
              },
            }}
          />
        </Box>
      </Backdrop>
    </>
  );
}
