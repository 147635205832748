import { useState, useEffect } from "react";
import { getCountryCode } from "./countries_map";

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 3000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

function getCountryCodeInternal() {
  let code = getCountryCode();
  if (!code) {
    const locale = navigator.language || navigator.userLanguage;
    code = locale.split("-")[1];
  }
  return String(code).toUpperCase();
}

export function useCountryCode() {
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setCountryCode(getCountryCodeInternal());
    });
    /*fetchWithTimeout("https://ipinfo.io/json?token=a472c8ac4355d2")
      .then((response) => response.json())
      .then((data) => {
        setCountryCode(String(data.country));
      })
      .catch((error) => {
        setCountryCode(getCountryCodeInternal());
      });*/
  }, []);

  return countryCode;
}
