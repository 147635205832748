import React, { useEffect, useState } from "react";
import WarningLayout from "../Components/WarningLayout";
import PhoneNumber from "../Components/PhoneNumber";
import InformationTable from "../Components/InformationTable";
import AddRating from "../Components/AddRating";
import CommentsTable from "../Components/CommentsTable";
import { getTexts } from "../Languages/scripts/languages";
import { getNumberDoc } from "../Firebase/firestore";
import SimiliarNumbers from "../Components/SimiliarNumbers";
import SignInDialog from "../Components/SignIn/SignInDialog";
import AboutUs from "../Components/AboutUs";

export default function MainPage({ phoneNumber, country, texts, ...props }) {
  const [docData, setDocData] = useState(null);

  const articles = texts.getRandomArticles(texts.articles, 2);

  useEffect(() => {
    const loadDoc = async () => {
      const res = await getNumberDoc(
        phoneNumber?.nationalNumber,
        phoneNumber?.countryCallingCode
      );
      console.log("Document data:", res);
      setDocData(res);
    };

    if (phoneNumber?.nationalNumber && phoneNumber?.countryCallingCode) {
      loadDoc();
    }
  }, [phoneNumber]);

  function onDataAdd(rateData) {
    const { letter, text, date } = rateData;

    let newDocData = { ...docData };
    if (newDocData[letter]) {
      newDocData[letter] += 1;
    } else {
      newDocData[letter] = 1;
    }

    if (text) {
      if (newDocData.comments) {
        newDocData.comments.push({
          date,
          text,
          [letter]: true,
        });
      } else {
        newDocData.comments = [
          {
            date,
            text,
            [letter]: true,
          },
        ];
      }
    }
    console.log(newDocData);
    setDocData(newDocData);
  }

  if (
    !texts ||
    !phoneNumber?.nationalNumber ||
    !phoneNumber?.countryCallingCode
  )
    return <></>;

  return (
    <div>
      <PhoneNumber phoneNumber={phoneNumber} texts={texts} />
      <WarningLayout title={articles?.[0]?.title} text={articles?.[0]?.text} />
      <InformationTable
        phoneNumber={phoneNumber}
        texts={texts}
        docData={docData}
      />
      <WarningLayout title={articles?.[1]?.title} text={articles?.[1]?.text} />
      <CommentsTable
        phoneNumber={phoneNumber}
        texts={texts}
        docData={docData}
      />
      <AddRating
        {...props}
        phoneNumber={phoneNumber}
        texts={texts}
        onDataAdd={onDataAdd}
      />
      <SimiliarNumbers {...props} phoneNumber={phoneNumber} texts={texts} />
      <AboutUs texts={texts} />
    </div>
  );
}
