import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Motion } from "./Motion";
import { boxPadding } from "../Styles/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { theme } from "../../App";

function generateSimilarNumbers(inputNumber, maxResults) {
  const inputStr = inputNumber.toString();
  const similarNumbers = new Set();

  function getNeighborDigits(digit) {
    const num = parseInt(digit, 10);
    const neighbors = [num];

    if (num > 1) neighbors.push(num - 1);
    if (num < 9) neighbors.push(num + 1);

    return neighbors;
  }

  function generateCombinations(index, current) {
    if (similarNumbers.size >= maxResults * 3) return;
    if (index === inputStr.length) {
      const newNumber = current.join("");
      if (newNumber !== inputStr) {
        similarNumbers.add(newNumber);
      }
      return;
    }

    const digit = inputStr[index];
    const neighbors = getNeighborDigits(digit);

    neighbors.forEach((neighbor) => {
      current[index] = neighbor;
      generateCombinations(index + 1, current);
    });
  }

  generateCombinations(0, Array(inputStr.length).fill(0));
  const shuffled = Array.from(similarNumbers).sort(() => 0.5 - Math.random());
  return shuffled.slice(0, maxResults);
}

export default function SimiliarNumbers({ texts, phoneNumber, ...props }) {
  const { bg, txt } = props;
  const navigate = useNavigate();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang");

  const MAX_NUM = xs ? 6 : sm ? 12 : 20;

  return (
    <Container maxWidth="lg" sx={{ pb: "24px" }}>
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ...boxPadding,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Typography
              sx={{ ml: "8px", fontWeight: "bold", fontSize: "18px" }}
            >
              {texts.sn}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            ...boxPadding,
            pt: "0px",
          }}
        >
          {generateSimilarNumbers(phoneNumber?.nationalNumber, MAX_NUM).map(
            (number, i) => (
              <Box
                key={`SimilNum` + i}
                sx={{
                  flex: xs ? 1 : "none",
                  display: "flex",
                  justifyContent: xs ? "center" : "normal",
                }}
              >
                <Motion>
                  <Box
                    sx={{
                      bgcolor: bg,
                      width: "fit-content",
                      borderRadius: "24px",
                      mt: "8px",
                      mr: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(
                        `/${phoneNumber?.countryCallingCode || ""}${number}${
                          lang ? `?lang=${lang}` : ""
                        }`
                      );
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        py: "8px",
                        px: "28px",
                        textAlign: "center",
                      }}
                    >
                      {number}
                    </Typography>
                  </Box>
                </Motion>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Container>
  );
}
