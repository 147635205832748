import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import SignInMethods from "./SignInMethods";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Motion } from "../Motion";
import { Box } from "@mui/material";
import { textColor } from "../../Styles/styles";

export default function SignInDialog({
  open = false,
  handleClose = () => {},
  dialog = undefined,
  texts,
  onSignIn,
}) {
  if (dialog) {
    open = Boolean(dialog) || open;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        zIndex: 999999,
        ".MuiPaper-root": {
          borderRadius: "24px",
          minWidth: "310px",
          width: "410px",
          maxWidth: "430px",
          minHeight: "160px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          overflowX: "hidden",
        },
      }}
    >
      <DialogContent
        sx={[
          {
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            flexDirection: "column",
            p: "32px",
          },
        ]}
      >
        <Box sx={{ position: "absolute", top: 10, right: 10 }}>
          <Motion>
            <Box
              sx={{
                ml: "auto",
                cursor: "pointer",
                p: "3px",
                height: "24px",
                maxHeight: "24px",
              }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseRoundedIcon sx={{ fill: textColor }} />
            </Box>
          </Motion>
        </Box>
        <DialogContentText
          sx={{
            fontSize: "18px",
            color: "black",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
          }}
          id="alert-dialog-description"
        >
          {texts.sitc}
        </DialogContentText>
        <DialogContentText
          sx={{
            fontSize: "16px",
            lineHeight: "19px",
            color: "black",
            fontWeight: "normal",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
          }}
          id="alert-dialog-description"
        >
          {texts.rwnc}
        </DialogContentText>
        <SignInMethods texts={texts} onSignIn={onSignIn} />
      </DialogContent>
    </Dialog>
  );
}
