import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import { borderColor, boxPadding, textBlue, textColor } from "../Styles/styles";
import { Motion } from "./Motion";
import Table from "./Table";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import { getRateOptions } from "./AddRating";
import { dateToVisual, timestampToDate } from "./InformationTable";
import { theme } from "../../App";

function RateButton({ ...props }) {
  const showText = useMediaQuery(theme.breakpoints.down("900"));
  const smallest = useMediaQuery(theme.breakpoints.down("410"));
  const { select, setSelect, texts, rateOptions } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mt: "20px",
        mb: "8px",
      }}
    >
      {rateOptions.map((data, i) => (
        <Box
          key={"RatingOpt" + i}
          sx={{
            mr: "-1px",
            flex: "1 1 0",
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "center", sm: "center", md: "normal" },
            p: "12px",
            pl: { xs: "3px", sm: "16px", md: "24px" },
            pr: { xs: "3px", sm: "16px", md: "15px" },
            borderTopLeftRadius: i === 0 ? "12px" : "0px",
            borderBottomLeftRadius: i === 0 ? "12px" : "0px",
            borderTopRightRadius: i === rateOptions.length - 1 ? "12px" : "0px",
            borderBottomRightRadius:
              i === rateOptions.length - 1 ? "12px" : "0px",
            border: `1px solid ${borderColor}`,
            zIndex: select === i ? "2" : "1",
          }}
          onClick={() => {}}
        >
          {data.icon}{" "}
          <Typography
            sx={{
              color: textColor,
              fontWeight: "bold",
              ml: { xs: smallest ? "1px" : "6px", sm: "8px" },
              whiteSpace: "nowrap",
            }}
          >
            {!showText ? `${data.text}: ${data.count}` : `${data.count}`}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

export default function CommentsTable({ texts, phoneNumber, docData }) {
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const xxs = useMediaQuery(theme.breakpoints.down("425"));

  //Ratings
  function getRatingsData() {
    let rateOptions = [...getRateOptions(texts)];

    for (let i = 0; i < rateOptions.length; i++) {
      const rt = docData?.[rateOptions[i].short] || 0;
      rateOptions[i].count = rt;
    }

    return rateOptions;
  }

  // Comments
  function getRateOptionFromComment(comment) {
    function RateContainer({ rateOption }) {
      return (
        <Box
          sx={{
            borderRadius: "24px",
            bgcolor: rateOption.color,
            width: "fit-content",
          }}
        >
          <Typography
            sx={{ color: "white", fontWeight: "bold", py: "3px", px: "28px" }}
          >
            {rateOption.text}
          </Typography>
        </Box>
      );
    }

    const rateOptions = getRateOptions(texts);
    for (let i = 0; i < rateOptions.length; i++) {
      if (comment?.[rateOptions[i].short] === true) {
        return <RateContainer rateOption={rateOptions[i]} />;
      } else {
        continue;
      }
    }
    return <RateContainer rateOption={{ text: texts.un, color: "#959595" }} />;
  }

  function getRateIconFromComment(comment) {
    const rateOptions = getRateOptions(texts);
    for (let i = 0; i < rateOptions.length; i++) {
      if (comment?.[rateOptions[i].short] === true) {
        return rateOptions[i].icon;
      } else {
        continue;
      }
    }
    return rateOptions[2].icon;
  }

  function getCommentData() {
    let comments = [];
    const commentData = docData?.comments;
    if (!commentData || commentData.length == 0) return [];

    const slicedData = commentData.slice(0, 20);

    for (let i = 0; i < slicedData.length; i++) {
      let cData = [];
      if (!xs && !sm) {
        cData.push(getRateOptionFromComment(slicedData[i]));
      }
      cData.push(
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          {(xs || sm) && (
            <Box sx={{ mr: xxs ? "2px" : "14px", height: "24px" }}>
              {getRateIconFromComment(slicedData[i])}
            </Box>
          )}
          {slicedData[i].text}
        </Box>
      );
      if (!xs) {
        cData.push(<b>{dateToVisual(timestampToDate(slicedData[i].date))}</b>);
      }

      comments.push(cData);
    }

    return comments;
  }

  return (
    <Container maxWidth="lg" sx={{ pb: "24px" }}>
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ...boxPadding,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Motion>
              <Box
                sx={{
                  height: "25px",
                  maxHeight: "25px",
                }}
              >
                <CommentRoundedIcon sx={{ fill: textBlue }} />
              </Box>
            </Motion>
            <Typography
              sx={{ ml: "8px", fontWeight: "bold", fontSize: "18px" }}
            >
              {texts.c}
            </Typography>
          </Box>
          <RateButton texts={texts} rateOptions={getRatingsData()} />
          {!docData?.comments || docData?.comments?.length == 0 ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold", mt: "16px", mb: "8px" }}>
                {texts.afct}
              </Typography>
            </Box>
          ) : (
            <Table dataTable={getCommentData()} />
          )}
        </Box>
      </Box>
    </Container>
  );
}
