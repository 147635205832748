import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyApVBlOGz5RKqm--GZntiEzgvHBRYmcT6g",
  authDomain: "callnumbers.firebaseapp.com",
  projectId: "callnumbers",
  storageBucket: "callnumbers.appspot.com",
  messagingSenderId: "394254098613",
  appId: "1:394254098613:web:a4f206a78be10e0428defc",
  measurementId: "G-E29F8Y3C0S",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Firestore
export const db = getFirestore(app);

// Auth
export const auth = getAuth(app);
auth.useDeviceLanguage();
