import React from "react";
import XIcon from "@mui/icons-material/X";
import { siIconSize } from "../SignInMethods";

export default function Twitter() {
  return (
    <div style={{ width: siIconSize, height: siIconSize }}>
      <XIcon sx={{ fill: "white", width: siIconSize, height: siIconSize }} />
    </div>
  );
}
