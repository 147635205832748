import React from "react";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import { Box, Container, Typography } from "@mui/material";
import { Motion } from "./Motion";
import { boxPadding, textBlue } from "../Styles/styles";

export default function PhoneNumber({
  phoneNumber,
  texts,
  isNotFound = false,
  main = false,
}) {
  return (
    <Container maxWidth="lg" sx={{ pb: "24px" }}>
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ...boxPadding,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignContent: "center",
              textAlign: "left",
            }}
          >
            <Motion>
              <Box
                sx={{
                  width: "16px",
                  height: "18px",
                  maxHeight: "18px",
                }}
              >
                <PhoneIphoneRoundedIcon
                  sx={{ fill: textBlue, width: "16px", height: "16px" }}
                />
              </Box>
            </Motion>
            <Typography
              sx={{
                ml: "8px",
                fontWeight: "bold",
                color: textBlue,
              }}
            >
              {texts.pntc}
            </Typography>
            <Motion>
              <Box
                sx={{
                  ml: "auto",
                  p: "3px",
                  height: "24px",
                  maxHeight: "24px",
                }}
              ></Box>
            </Motion>
          </Box>
          <Typography
            component="h1"
            sx={{ fontWeight: "bold", fontSize: "32px", lineHeight: "36px" }}
          >
            {main
              ? texts.capn
              : isNotFound
              ? texts.nnf
              : phoneNumber?.nationalNumber}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            {isNotFound
              ? texts.ttsb
              : `${texts.cc}: +${phoneNumber?.countryCallingCode} (${phoneNumber?.nationalNumber}; ${phoneNumber?.number})`}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
