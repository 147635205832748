import { Box, Container } from "@mui/material";
import React from "react";
import WarningLayout from "../Components/WarningLayout";

export default function WarnPage({ texts }) {
  const articles = texts.getRandomArticles(texts.articles);

  return (
    <Container maxWidth="lg" sx={{}}>
      {articles.map((data, i) => (
        <WarningLayout
          key={"WarnLay" + i}
          title={data?.title}
          text={data?.text}
        />
      ))}
    </Container>
  );
}
